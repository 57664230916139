import React, { useEffect, useState } from 'react'
import { type StackProps, YStack } from 'tamagui'

interface BaseSkeletonProps extends StackProps {
  width: number
  height: number
  backgroundColor?: string
  margin?: number
}

export const BaseSkeleton: React.FC<BaseSkeletonProps> = ({
  width,
  height,
  backgroundColor = '#99B3B94D',
  margin = 8,
  ...rest
}) => {
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    const blink = (): void => {
      setIsVisible((prev) => !prev)
      setTimeout(blink, 1000)
    }
    const timerId = setTimeout(blink, 1000)
    return (): void => clearTimeout(timerId)
  }, [])

  return (
    <YStack
      margin={margin}
      borderRadius={4}
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      opacity={isVisible ? 1 : 0.4}
      {...rest}
    />
  )
}
