export enum GetProductsFilterType {
  BRAND_ID,
  CATEGORY,
  COLOR,
  SIZE,
  CATEGORY_PREFIX,
  LIST_ID,
  PRICE_RANGE,
  SEARCH_TERM,
  SUPPLIER,
}

export enum PrepaidCartReason {
  HAS_HALLOWEEN_ITEMS = 'HAS_HALLOWEEN_ITEMS',
  THRESHOLD_EXCEEDED = 'THRESHOLD_EXCEEDED',
  BANNED_FROM_CASH = 'BANNED_FROM_CASH',
  ACTIVE_THRESHOLD_EXCEEDED = 'ACTIVE_THRESHOLD_EXCEEDED',
  NATIONAL_DELIVERY = 'NATIONAL_DELIVERY',
  MAX_ONGOING_CASH_ORDERS_TOTAL = 'MAX_ONGOING_CASH_ORDERS_TOTAL',
}

export enum OrderItemAlternativeOption {
  DEFAULT = 'DEFAULT',
  PICK_MORE_ALTERNATIVE = 'PICK_MORE_ALTERNATIVE',
  CANCEL_NO_ALTERNATIVE = 'CANCEL_NO_ALTERNATIVE',
  PENDING_CONTACT_CX = 'PENDING_CONTACT_CX',
}

export const PrepaidCartReasonText: Record<PrepaidCartReason, string> = {
  [PrepaidCartReason.HAS_HALLOWEEN_ITEMS]:
    'Los productos de Halloween no admiten devoluciones o pago contra entrega',
  [PrepaidCartReason.THRESHOLD_EXCEEDED]:
    'Los pedidos con total superior a $200.000 no admiten pago contra entrega',
  [PrepaidCartReason.BANNED_FROM_CASH]:
    'El método de pago contra entrega se encuentra temporalmente deshabilitado debido a que has abandonado muchos pedidos últimamente',
  [PrepaidCartReason.ACTIVE_THRESHOLD_EXCEEDED]:
    'La suma de tus pedidos activos y este pedido exceden los $200.000 por lo que ya no se admite pago contra entrega',
  [PrepaidCartReason.NATIONAL_DELIVERY]:
    'Los pedidos con envío nacional no admiten pago contra entrega',
  [PrepaidCartReason.MAX_ONGOING_CASH_ORDERS_TOTAL]:
    'La suma de tus pedidos activos y este pedido exceden los $200.000 por lo que ya no se admite pago contra entrega',
}
